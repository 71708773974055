import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import AuthReducer from '@/reducers/user.js'

const rootPersistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
};

const rootReducer = persistReducer(rootPersistConfig, combineReducers({
    auth: AuthReducer,
}));

export const store = createStore(rootReducer);
export const persistor = persistStore(store);