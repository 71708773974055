import React, { useState } from 'react';

import {  Modal,Divider,Button } from 'antd';
import {
    AndroidFilled,
    AppleOutlined,
    DownloadOutlined,
    QrcodeOutlined,
} from '@ant-design/icons';

import QRCode  from 'qrcode.react';
import { formatFileSize } from "@/utils/file.js"
import "./app-down-info.css"

var moment = require('moment');

function AppDownInfo({ app }) {

    const [isLoading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showLayer, setShowLayer] = useState('none');

    let ua= window.navigator.userAgent.toLowerCase();
    let inwx = ua.match(/wxwork/i == 'wxwork') ||  ua.match(/micromessenger/i) == 'micromessenger' ? true : false; //eslint-disable-line
    let isIOS = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent) ? true : false; 
    let btnStyle = {
        "type":"primary",
        "disabled":false,
        "txt":"下载("+formatFileSize(app.version.size)+")"
    }

    // const loadVersions = () => {
    //     request.post("/api/version/list_by_id.osp", {
    //         id: app.type === 2 ? app.id : app.relation_id,
    //         limit:5
    //     })
    //         .then((data) => {
    //             setIosVersions(data.list)
    //         })
    //     request.post("/api/version/list_by_id.osp", {
    //         id: app.type === 1 ? app.id : app.relation_id,
    //         limit:5
    //     })
    //         .then((data) => {
    //             setAndVersions(data.list)
    //         })
    // };
    if(isIOS && app.type===1) {
        btnStyle.txt = "此链接只支持安卓系统下载";
        btnStyle.disabled = true;
    } else if(!isIOS && app.type===2)  {
        btnStyle.txt = "此链接只支持iOS系统下载";
        btnStyle.disabled = true;
    }
    function downloadCode() {
      var Qr=document.getElementById('qrid');  
      let image = new Image();
      image.src = Qr.toDataURL("image/png");
      var a_link=document.getElementById('aId');
      a_link.href=image.src;
    }
      function handleCancel() {
        setShowModal(false)
      };

    // console.log("inwx:"+inwx+ " isIOS:"+isIOS+" app.type:"+app)
    // useEffect(loadVersions, [])
    // console.log(app.version.logs)
    // app.version.logs = app.version.logs.replace("\n","<br />")
    // app.version.logs = app.version.logs.replace(/(\r\n|\r|\n)+/g, '$1').replace(/\r\n/g,"<br>")
    // app.version.logs = app.version.logs.replace(/\n/g,"<br>");
    return (
        <div >
            <div style={{paddingBottom:'40px'}}>
                <div className="wxtip" style={{display:showLayer}}>
                    <span className="wxtip-icon"></span>
                </div>
                <div className="icon-container wrapper">
                    <span className="icon">
                        <img
                            alt="app icon"
                            src={`data:image/jpeg;base64,${app.icon}`} />
                    </span>
                </div>
                <h1 className="name">
                    <span>
                        {
                            app.type === 1 ? (<AndroidFilled className="name-icon" />) : (<AppleOutlined className="name-icon" />)
                        }
                        {app.name}
                    </span>
                </h1>
                <div className="release-info">
                    <p><span>{app.version.code} - {formatFileSize(app.version.size)}</span> <span onClick={() => { setShowModal(true)}} ><QrcodeOutlined/></span></p>  
                    <p>更新于: <span >{moment(app.version.updateTime * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
                    </p> 
                     <Modal
                    title="下载二维码"
                    visible={showModal}
                    width={160}
                    footer={null}
                    onCancel={()=>{handleCancel()}}
                    mask
                    >
                        <span download id='aId' title="下载二维码">
                            <QRCode
                                id="qrid"
                                value={window.location.href} 
                                size={128}
                                fgColor="#000000"
                                style={{ margin: 'auto' }}
                                onClick={() => {
                                    downloadCode()
                                }} 
                            />
                        </span>
                    </Modal>                
                    <Divider />
                    {/* <Card size="small" title="更新日志" style={{textAlign:'left',bottom:'35px'}}>
                          <div className="update_log" dangerouslySetInnerHTML={{ __html: app.version.logs }}/>
                    </Card> */}

                </div>
            </div>
            <div className="install-btn">
            <div style={{marginBottom:'15px', marginTop:'10px',padding:'10px 10px 0 10px'}}>
            {
                inwx === true ?  <Button style={{lineHeight:1.7}}
                    type={btnStyle.type}
                    shape="round"
                    icon={<DownloadOutlined />}
                    size="large"
                    href="#"
                    onClick={() => {
                        setShowLayer('block')
                    }} block disabled={btnStyle.disabled}>
                    {btnStyle.txt}
                </Button> :
                <Button style={{lineHeight:1.7}}
                    type={btnStyle.type}
                    shape="round"
                    icon={<DownloadOutlined />}
                    size="large"
                    href={app.version.path}
                    loading={isLoading}
                    onClick={() => {
                        setLoading(true)
                    }} block disabled={btnStyle.disabled}>
                     {btnStyle.txt}
                </Button>
            }
            </div>
            </div>
        </div>
    );
}

export default AppDownInfo