import axios from 'axios';

const request = axios.create({
    //当创建实例的时候配置默认配置
    headers: { 'Content-Type': 'application/json' },
    xsrfCookieName: 'xsrf-token'
});

request.interceptors.request.use(
    config => {
        // 在发送请求前的动作，比如加loading
        // 给请求添加token
        var token = localStorage.getItem('accessToken')
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = token
        }
        return config
    },
    err => {
        // 请求错误
        return Promise.reject(err)
    }
)

//添加一个响应拦截器
request.interceptors.response.use(function (response) {
    // 1.成功
    const code = response.data.code;
    if (code === 1) {
        return response.data.data;
    }else if (code > 99 && code < 200) {
        //需要重新登录
        localStorage.removeItem('accessToken');
        window.location.href = '/login';
    }

    // 2.其他失败，比如校验不通过等
    return Promise.reject(response.data);
}, function () {
    // Toast.hide();
    // // 4.系统错误，比如500、404等
    // Toast.info('系统异常，请稍后重试！', 1);
    return Promise.reject({
        code: -100,
        msg: "请求失败，请稍后再试"
    });
});

export default request;