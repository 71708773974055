import React from 'react';
import TopNav from './top-nav.jsx'

import { Layout } from 'antd';

function BaseLayout({ children }) {

    return (
        <Layout>
            <TopNav />
            <Layout style={{ paddingTop: 74, height: '100%' }}>
                <Layout.Content style={{ overflow: 'auto', height: '100%',  width: '100%' }}>
                    <div style={{  minHeight: 'calc(100% - 50px)' }}>
                        {children}
                    </div>
                    <Layout.Footer style={{
                        textAlign: 'center',
                        height: 50,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        position: 'relative',
                        float: 'bottom'
                    }}
                    >
                        TestPlane ©2020 Dubmic Inc.
                    </Layout.Footer>
                </Layout.Content>
            </Layout>
        </Layout>

    );
}

export default BaseLayout;