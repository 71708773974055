import React, { useEffect, useState } from 'react';
import { Spin, List, Card, Modal, Button, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';

import UpdateModal from '@/widgets/update.jsx'
import AppItem from '@/widgets/app-item.jsx'

import request from '@/api/axiosCore.js'


function Home() {
    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const loadData = () => {
        request.post("/api/app/list.osp")
            .then((data) => {
                setData(data.list)
                setLoading(false)
            }, (data) => {
                setLoading(false)
                message.error(data.msg)
            })
    };


    function showUpload() {
        Modal.confirm({
            icon: null,
            content: <UpdateModal onSuccess={() => {
                Modal.destroyAll();
                loadData();
            }} />,
            okText: '关闭',
        });
    }

    useEffect(loadData, [])

    return (

        <div style={{ width: 960, margin: "0 auto" }}>
            <div style={{ textAlign: "right", marginTop: 36 }}>

                <Button type="primary"
                    shape="round"
                    icon={<CloudUploadOutlined />}
                    size="large"
                    onClick={showUpload}
                >
                    上传
                </Button>
            </div>

            <div className="table-container" style={{ minHeight: 500 }}>
                <div className="cell-container" style={{ minHeight: 500 }}>
                    <Spin spinning={isLoading} size="large">
                        {
                            !isLoading ? (
                                <List
                                    grid={{
                                        gutter: 24,
                                        column: 3
                                    }}
                                    style={{ marginTop: 24 }}
                                    dataSource={data}
                                    renderItem={item => (
                                        <List.Item>
                                            <Card
                                                hoverable
                                                style={{ width: 300 }}
                                            >
                                                <AppItem app={item} />
                                            </Card>

                                        </List.Item>
                                    )}
                                />
                            ) : null
                        }
                    </Spin>

                </div>
            </div>
        </div>
    );
}

export default Home;