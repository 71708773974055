const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'
const USERDATA = 'USERDATA'

const INITIAL_STATE = {
    isLogin: false
}

export default function AuthReducer(state = INITIAL_STATE, action) {

    switch (action.type) {
        case LOGIN:
            return action.user
        case LOGOUT:
            return {}
        case USERDATA:
            return state;
        default:
            return state;
    }
}

export function login(user) {
    return {
        type: LOGIN,
        user: user
    }
}

export function logout() {
    return { type: LOGOUT }
}

// const authStore = createStore(autheducer, initialState)

// export default authStore;