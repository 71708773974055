import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import './App.less';

import Layout from '@/components/layout.jsx'

import Home from '@/pages/home/index.jsx'
import SettingLayout from '@/pages/setting/layout.jsx'
import SettingUser from '@/pages/setting/users.jsx'
import UserCreate from '@/pages/setting/user-new.jsx'
import SettingTokens from '@/pages/setting/token.jsx'
import SettingDevices from '@/pages/setting/devices.jsx'

import Login from '@/pages/login/index.jsx'
import Page404 from '@/pages/error/page404.jsx'
import DownloadApp from "@/pages/download/app.jsx"
import AppNewest from "@/pages/download/project.jsx"


const App = () => {

  let SettingRouter = (
    <SettingLayout>
      <Switch>
        <Route exact path="/setting/users.page" component={SettingUser} />
        <Route exact path="/setting/user/create.page" component={UserCreate} />
        <Route exact path="/setting/token.page" component={SettingTokens} />
        <Route exact path="/setting/devices.page" component={SettingDevices} />
        <Redirect exact from="/setting" to="/setting/users.page" />
        <Route component={Page404} />
      </Switch>
    </SettingLayout>
  );


  let LayoutRouter = (
    <Layout>
      <Switch>
        <Route path="/setting/" render={props => {
          return SettingRouter
        }} />
        <Redirect exact from="/user" to="/user/index.page" />
        <Route exact path="/" component={Home} />
        <Route component={Page404} />
      </Switch>
    </Layout>
  );


  const isLogin = localStorage.getItem('accessToken') != null;
  return (
    <Router>
      <Switch>
        <Route path="/app/:key.page" component={DownloadApp} />
        <Route path="/newest/:key.page" component={AppNewest} />
        <Route path="/login.page" render={props => {
          return isLogin ? <Redirect to="/" /> : <Login />
        }} />
        <Route path="/" render={props => {
          return isLogin ? LayoutRouter : <Redirect to="/login.page" />
        }} />

      </Switch>
    </Router>
  );
}

export default App;
