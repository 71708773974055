import React from 'react';

import { Layout } from 'antd';
import { Menu } from 'antd';
import { UserOutlined, TeamOutlined, FolderOpenOutlined, KeyOutlined, MobileOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'

import "./layout.css"

function SettingLayout({ children }) {

    return (
        <Layout style={{ height: '100%' }}>
            <Layout.Sider className="site-layout-background" width={200}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    style={{ height: '100%' }}
                >
                    <Menu.Item key="1" icon={<UserOutlined />} >
                        <Link to="/setting/users.page">用户管理</Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<TeamOutlined />} >
                        <Link to="/setting/team.page">组织管理</Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<FolderOpenOutlined />} >
                        <Link to="/setting/team.page">磁盘管理</Link>
                    </Menu.Item>
                    <Menu.Item key="4" icon={<KeyOutlined />} >
                        <Link to="/setting/token.page">授权管理</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<MobileOutlined />} >
                        <Link to="/setting/devices.page">设备管理</Link>
                    </Menu.Item>

                </Menu>
            </Layout.Sider>
            <Layout.Content
                style={{ overflow: 'auto', height: '100%', padding: 16, width: '100%' }}
            >
                <div
                    className="site-layout-background"
                    style={{ padding: 16, minHeight: 'calc(100% - 50px)' }}>
                    {children}
                </div>
            </Layout.Content>
        </Layout>

    );
}

export default SettingLayout;