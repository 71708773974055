import React from 'react';
import { Link } from 'react-router-dom'
import { Button } from 'antd';
import { AndroidFilled, AppleFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';

import { formatFileSize } from "@/utils/file.js"
import "./app-item.css"

var moment = require('moment');

const AppItem = ({ app }) => {

    return (
        <div>
            <div className="type-mark" />
            {
                app.type === 1 ? (<AndroidFilled className="type-icon" />) : (<AppleFilled className="type-icon" />)
            }

            <img style={{ width: 80, height: 80 }}
                alt="app icon"
                src={`data:image/jpeg;base64,${app.icon}`} />

            <br />
            <p style={{ marginTop: 24, fontSize: 18 }}>
                <span>{app.name}</span>
            </p>
            <table>
                <tbody>
                    <tr>
                        <td className="appItem-info-title">包名：</td>
                        <td className="appItem-info-appInfo">{app.packageName}</td>
                    </tr>
                    <tr>
                        <td className="appItem-info-title">版本：</td>
                        <td className="appItem-info-appInfo"> {app.version.code} </td>
                    </tr>
                    <tr>
                        <td className="appItem-info-title">大小：</td>
                        <td className="appItem-info-appInfo"> {formatFileSize(app.version.size)} </td>
                    </tr>
                    <tr>
                        <td className="appItem-info-title">时间：</td>
                        <td className="appItem-info-appInfo">
                            {moment(app.version.updateTime * 1000).format('YYYY-MM-DD HH:mm:ss')}
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className="appItem-action-button">
                <Button
                    shape="round"
                    style={{ marginRight: 12 }}
                    icon={<EditOutlined />} >
                    编辑
                </Button>

                <Link to={`/app/${app.version.alias}.page`}>
                    <Button
                        shape="round"
                        icon={<EyeOutlined />} >
                        预览
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default AppItem