import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Layout, Dropdown, Menu, Space } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';

import { logout } from '@/reducers/user.js'

const TopNav = ({ userInfo, logout }) => {

    const onLogout = () => {
        localStorage.removeItem('accessToken');
        logout();
        window.location.href = '/login';
    }

    const menu = (
        <Menu>
            <Menu.Item style={{ textAlign: 'center' }}>
                <Link to="/user/">个人资料</Link>
            </Menu.Item>
            <Menu.Item style={{ textAlign: 'center' }}>
                <Link to="/setting">设置</Link>
            </Menu.Item>
            <Menu.Item style={{ textAlign: 'center' }} onClick={onLogout}>退出</Menu.Item>
        </Menu>
    )

    return (
        <Layout.Header
            className="header-layout"
            style={{ position: 'fixed', zIndex: 1, width: '100%', paddingLeft: 24, paddingRight: 24 }}>
            <Link to="/">
                <div className="logo" />
            </Link>

            <div style={{ float: "right" }}>
                <Dropdown overlay={menu} placement="bottomRight">
                    <Space>
                        <span >欢迎，{userInfo.name}</span>
                        <span><CaretDownFilled style={{ color: '#FFFFFF' }} /></span>
                    </Space>
                </Dropdown>

            </div>
        </Layout.Header>
    )
}

export default connect(
    (state) => {
        return { userInfo: state.auth }
    },
    (dispatch) => {
        return {
            logout: () => dispatch(logout())
        }
    })(TopNav);