import React from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';


const { Dragger } = Upload;

const UpdateModal = ({ onSuccess }) => {

    const props = {
        name: 'file',
        multiple: false,
        action: '/api/app/upload.osp',
        headers: {
            Authorization: localStorage.getItem('accessToken'),
        },
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                console.log(info.file.response);
                if(info.file.response.code === 1){
                    onSuccess()
                }else{
                    message.error(info.file.response.msg)
                }
            } else if (status === 'error') {
                message.error("上传失败")
            }
        },

    };

    return (
        <div>
            <br />
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击上传</p>
                <p className="ant-upload-hint">
                    或者把文件拖动到此处
            </p>
            </Dragger>
        </div>
    );
}

export default UpdateModal