import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Card, Row, Col, Form, Input, Button, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import "./index.css"


import request from '@/api/axiosCore.js'
import { login } from '@/reducers/user.js'

const Login = ({ login }) => {
    const [isLoading, setLoading] = useState(false);
    const onFinish = values => {
        setLoading(true)
        request.post("/api/login.osp", values)
            .then((data) => {
                //此处为正常业务数据的处理
                setLoading(false)
                login(data.user);
                localStorage.setItem('accessToken', data.accessToken)
                window.location.href = '/';
            }, (data) => {
                setLoading(false)
                message.error(data.msg);
            });
    };

    return (
        <Row className="user-login-panel" justify="center" style={{ height: '100vh', width: '100vw', background: '#efefef' }}>
            <Col xs={18} sm={12} md={10} lg={8} style={{ marginTop: 100 }}>

                <Card title="欢迎登录NB系统" bordered={true} headStyle={{ textAlign: "center" }}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="mobile"
                            rules={[{ required: true, message: '没有输入用户名' }]}
                        >
                            <Input
                                className="user-login-input"
                                prefix={<UserOutlined className="login-form-input-icon" />}
                                placeholder="手机号" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '没有输入密码' }]}
                        >
                            <Input
                                className="user-login-input"
                                prefix={<LockOutlined className="login-form-input-icon" />}
                                type="password"
                                placeholder="密码"
                            />
                        </Form.Item>


                        <Form.Item>
                            <Button block type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )

}

export default connect(
    (state) => {
        return {}
    },
    (dispatch) => {
        return {
            login: (userInfo) => dispatch(login(userInfo))
        }
    })(Login);
