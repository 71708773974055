import React from 'react';
import { Row, Col, Empty } from 'antd';

function Footer() {

    return (
        <Row justify="center">
            <Col xs={18} sm={12} md={10} lg={8} style={{ paddingTop: '20%' }}>
                <Empty description='页面不存在' image={Empty.PRESENTED_IMAGE_DEFAULT} />
            </Col>
        </Row>
    )

}

export default Footer