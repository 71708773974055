import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    message,
} from 'antd';
import axios from '@/api/axiosCore.js'

function SettingTokens() {

    const [isLoading, setLoading] = useState(false);
    const [token, setToken] = useState("");

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();
    const onFinish = values => {
        setLoading(true)
        axios.post("/api/token/create.osp", values)
            .then((data) => {
                //此处为正常业务数据的处理
                setLoading(false)
                setToken(data)
                console.log(data);
            }, (data) => {
                setLoading(false)
                message.error(data.msg);
            });
    };

    return (
        <div>

            <Form
                {...formItemLayout}
                style={{ maxWidth: 500,marginTop:30 }}
                form={form}
                name="register"
                onFinish={onFinish}
                initialValues={{
                    residence: ['zhejiang', 'hangzhou', 'xihu'],
                    prefix: '86',
                }}
                scrollToFirstError
            >


                <Form.Item
                    name="name"
                    label="姓名"
                    rules={[{ required: true, message: '姓名不能为空!', whitespace: true }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" htmlType="submit" loading={isLoading} >
                        生成
                </Button>
                </Form.Item>
            </Form>

            <div style={{margin:50,wordBreak:"break-all",wordWrap:"break-word"}}>
                {token}
            </div>
        </div>
    )
}
export default SettingTokens;