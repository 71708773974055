import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    message,
} from 'antd';
import axios from '@/api/axiosCore.js'


function UserCreate() {
    const [isLoading, setLoading] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();
    const onFinish = values => {
        setLoading(true)
        axios.post("/api/user/create.osp", values)
            .then((data) => {
                //此处为正常业务数据的处理
                setLoading(false)
                window.history.back();
            }, (data) => {
                setLoading(false)
                message.error(data.msg);
            });
    };

    return (
        <Form
            {...formItemLayout}
            style={{ maxWidth: 500 }}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
                residence: ['zhejiang', 'hangzhou', 'xihu'],
                prefix: '86',
            }}
            scrollToFirstError
        >


            <Form.Item
                name="name"
                label="姓名"
                rules={[{ required: true, message: '姓名不能为空!', whitespace: true }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="mobile"
                label="手机号"
                rules={[{ required: true, message: '手机号不能为空!', whitespace: true }]}
            >
                <Input style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="password"
                label="密码"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
                hasFeedback
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="confirm"
                label="验证密码"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" loading={isLoading} >
                    添加
                </Button>
            </Form.Item>
        </Form>
    )
}
export default UserCreate;