import React, { useEffect, useState } from 'react';
import { Spin, Table, Tag, Space, Button, message } from 'antd';
import axios from '@/api/axiosCore.js'

function SettingDevices() {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    const loadData = () => {
        setLoading(true)
        axios.post("/api/device/list.osp")
            .then((data) => {
                setData(data.list)
                setLoading(false)
            }, (data) => {
                setLoading(false)
                message.error(data.msg)
            })
    };

    const changeStatus = (id, status) => {
        setLoading(true)
        axios.post("/api/device/change_status.osp", {
            id: id.toString(),
            status: status.toString(),
        })
            .then((data) => {
                //setData(data.list)
                setLoading(false)
            }, (data) => {
                setLoading(false)
                message.error(data.msg)
            })
    }

    const columns = [
        {
            title: '姓名',
            dataIndex: 'user',
            width: '150px',
        },
        {
            title: '设备',
            dataIndex: 'name',
        },
        {
            title: '状态',
            dataIndex: 'status',
            align: 'center',
            width: '100px',
            render: status => (
                <span>
                    {
                        status === 1 ?
                            (
                                <Tag color={'green'} key={'green'}>
                                    正常
                                </Tag>
                            ) :
                            (
                                <Tag color={'gray'} key={'gray'}>
                                    禁用
                                </Tag>
                            )
                    }
                </span>
            ),
        },
        {
            title: 'Action',
            align: 'center',
            width: '100px',
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        type="link"
                        onClick={() => {
                            changeStatus(record.id, record.status === 1 ? 0 : 1)
                        }}>
                        {
                            record.status === 1 ? (<>禁用</>) : (<>启用</>)
                        }
                    </Button>
                </Space>
            ),
        },
    ];

    useEffect(loadData, [])

    return (
        <>
            <Spin spinning={isLoading} size="large" style={{ marginTop: 100 }}>
                {
                    <Table
                        style={{ marginTop: 40 }}
                        columns={columns}
                        pagination={{ position: ['bottomCenter'] }}
                        dataSource={data}
                        rowKey="mobile" />
                }
            </Spin>

        </>
    )

}
export default SettingDevices;