import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom"

import { Spin, message } from 'antd';

import AppDownInfo from '@/widgets/app-down-info.jsx'
import request from '@/api/axiosCore.js'
import "./app.css"

function DownloadApp() {
    const { key } = useParams()

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        request.post("/api/app/info.osp", {
            key: key,
        })
            .then((data) => {
                setData(data)
                setLoading(false)
                document.title = data.name
            }, (data) => {
                message.error(data.msg)
                setLoading(false)
            })
    }, [key])

    return (
        <div style={{ width: "100%", height: "100%", padding: 0, margin: 0 }}>
            <span className="pattern" style={{ left: 0 }}>
                <img
                    style={{ width: "100%" }}
                    alt=""
                    src="/image/download_pattern_left.png" />
            </span>

            <span className="pattern" style={{ right: 0 }}>
                <img
                    style={{ width: "100%" }}
                    alt=""
                    src="/image/download_pattern_right.png" />
            </span>

            <div className="main-container">
                <div className="table-container">
                    <div className="cell-container">
                        <Spin spinning={isLoading} size="large" >
                            {
                                !isLoading && data === null ? (
                                    <div>获取数据出错，请刷新</div>
                                ) : null
                            }

                            {
                                !isLoading && data != null ? (
                                    <AppDownInfo app={data} />
                                ) : null
                            }

                        </Spin>

                    </div>
                </div>

            </div>
        </div>
    );

}

export default DownloadApp